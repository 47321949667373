import {makeAutoObservable} from "mobx";
import {
    getBanners,
    getPopupBanners,
    getBackBtnLink,
    sendUserAction,
    getBannerStats
} from "../publickArticle/http/bannersApi";
import {getPosts} from "../publickArticle/http/postsApi";

export class Storeblog {
    posts = []
    allPosts = []
    selectedCategory = ''
    banners = []
    randomBanners = []
    popupBanners = []
    isLoading = false
    isError = ''
    urlParams = ''
    backBtnLink = []
    uniqBannerView = new Map();

    articleSeoName = ''
    bannersStatisticData = []

    totalPages = 0
    pages = 1


    constructor() {
        makeAutoObservable(this)

        this.loadUniqBannerView(); // get array of uniq banner
    }

    setPosts(posts) {
        this.posts = posts;
    }

    setBannersStatisticData(data) {
        this.bannersStatisticData = data;
    }

    setAllPosts(posts) {
        this.allPosts = posts;
    }

    setBanners(banners) {
        this.banners = banners;
    }
    setRandomBanners(randomBanners) {
        this.randomBanners = randomBanners;
    }

    setSelectedCategory(type) {
        this.setPages(1)
        this.setTotalPages(0)
        this.setPosts([])
        this.selectedCategory = type;
    }

    setIsLoading(loading) {
        this.isLoading = loading;
    }

    setIsError(error) {
        this.isError = error;
    }


    //Uniq Banner View
    setUniqBannerView(bannerID) {
        const currentTime = Date.now();
        this.uniqBannerView.set(bannerID, currentTime);
        this.saveUniqBannerView();
    }
    //End Uniq Banner View

    setUrlParams(params) {
        this.urlParams = params;
    }

    setPages(pages) {
        this.pages = pages;
    }

    setTotalPages(pages) {
        this.totalPages = pages;
    }
    setPopupBanners(banners){
        this.popupBanners = banners
    }
    setBackBtnLink(data){
        this.backBtnLink = data
    }

    async GetPosts(page, limit, category) {
        try {
            this.setIsLoading(true)
            const data = await getPosts(page, limit, category)
            const posts = data.posts
            const pages = data.page
            const totalPages = data.totalPages

            if (category.length !== 0) {
                const result = posts.filter(e => e.category === category)
                this.setPosts([...this.posts, ...result])
            } else {
                this.setPosts([...this.posts, ...posts])
            }
            this.setTotalPages(totalPages)
            this.setPages(+pages)
            this.setIsLoading(false)
        } catch (e) {
            console.log(e)
        }
    }

    async GetBanners(page, limit) {
        try {
            const data = await getBanners(page, limit)
            this.setBanners(data.banner)

            let randomArrBanner = [];
            data.banner.map( (e,i) => {
                let randomIndex = this.getRandomNumber(data.banner.length);

                if (!randomArrBanner.includes(data.banner[randomIndex])) {
                    if (randomArrBanner.length > 3) {
                        return false;
                    } else {
                        randomArrBanner.push(data.banner[randomIndex]);
                    }
                }
            })
            this.setRandomBanners(randomArrBanner)

        } catch (e) {
            console.log(e)
        }
    }
    async GetPopupBanners(){
        try {
            const data = await getPopupBanners();
            this.setPopupBanners(data);
        } catch (e) {
            console.log(e);
        }
    }

    async GetBackBtnLink(){
        try {
            const data = await getBackBtnLink();
            this.setBackBtnLink(data);

        } catch (e) {
            console.log(e);
        }
    }

    async GetUrlParams() {
        try {
            const currentURL = window.location.href;
            const urlParameters = currentURL.split('?')[1];

            if (urlParameters) {
                const paramsStart = currentURL.indexOf('?');
                const params = paramsStart !== -1 ? `?${currentURL.substring(paramsStart + 1)}` : '';

                const cookieExists = this.checkCookieExists('paramsString');
                if (cookieExists == null) {
                    this.setCookie('paramsString', params, 10)
                    this.setUrlParams(params);
                } else {
                    this.setUrlParams(cookieExists);
                }
                // console.log('Параметри URL:', urlParameters);
            } else {
                const cookieExists = this.checkCookieExists('paramsString');
                if (cookieExists !== null) {
                    this.setUrlParams(cookieExists);
                    // console.log('немає в юрл і є в кукі' , cookieExists)
                } else {
                    // console.log('немає в юрл і немає в кукі')
                }
                // console.log('URL не містить параметрів.');
            }

        } catch (e) {
            console.log(e)
        }
    }

    setCookie(cookieName, cookieValue, expirationDays) {
        const d = new Date();
        d.setTime(d.getTime() + (expirationDays * 24 * 60 * 60 * 1000));
        const expires = "expires=" + d.toUTCString();
        document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
    }

    checkCookieExists(cookieName) {
        const cookies = decodeURIComponent(document.cookie);
        const cookiesArray = cookies.split('; ');
        let result = '';
        cookiesArray.forEach(e => {
            if (e.indexOf(cookieName) !== -1) {
                result = e.replace(`${cookieName}=`, '');
            } else {
                result = null;
            }
        })
        return result;
    }

    getRandomNumber(max) {
        // Генерує випадкове число від 0 до (max - 1)
        return Math.floor(Math.random() * max);
    }


    //Banners Action
    loadUniqBannerView() {
        // Завантажуємо унікальні перегляди з localStorage
        const storedViews = localStorage.getItem('uniqBannerView');
        if (storedViews) {
            const parsedViews = JSON.parse(storedViews);
            const currentTime = Date.now();

            parsedViews.forEach(({ id, timestamp }) => {
                // Перевіряємо, чи не минуло три дні
                if (currentTime - timestamp <= 3 * 24 * 60 * 60 * 1000) {
                    this.uniqBannerView.set(id, timestamp);
                }
            });

            this.saveUniqBannerView();
        } else {
            localStorage.setItem('uniqBannerView', '[]');
        }
        console.log(Array.from(this.uniqBannerView.keys()), 'Loaded uniqBannerView');
    }

    saveUniqBannerView() {
        // Зберігаємо унікальні перегляди в localStorage
        const bannerArray = Array.from(this.uniqBannerView.entries()).map(([id, timestamp]) => ({ id, timestamp }));
        localStorage.setItem('uniqBannerView', JSON.stringify(bannerArray));
    }

    getUserAction(banner, article, action) {

        const date = new Date().toISOString();
        if (action === 'view') {
            if (!this.uniqBannerView.has(banner)) {
                // console.log(banner, article, 'uniqView', 'in');
                action = 'uniqView';
                this.setUniqBannerView(banner);
            }
        }
        sendUserAction(banner, article, action, date);
        console.log(banner, article, action, date)
    }
    //End Banners Action

    // GET BANNERS STATISTIC
    async getBannersStatistic(dateStart, dateEnd){
        try {
            const response = await getBannerStats(dateStart, dateEnd);
            const data = response.data;
            const sortedData = data.sort((a, b) => b.ctr - a.ctr);
            this.setBannersStatisticData(sortedData)
        } catch (e) {
            this.setIsError(e.response?.data?.message)
        }
    }
    // END GET BANNERS STATISTIC

}